import React from 'react';

import { Helmet } from 'react-helmet';
import { BASE_PAGE_TILE } from '../../lib/constants';

import Hero from '../../components/Hero/Hero';
import heroImageM from '../../images/a-person-in-orange-county-reading-cityside-fiber-blog.jpg';
import heroImageD from '../../images/a-person-in-orange-county-reading-cityside-fiber-blog.jpg';
import laptopInterconnect from '../../images/laptop-interconnect.jpg';
import fiberStock from '../../images/fiber-stock.jpg';
import blogApartment from '../../images/blog-apartment.jpg'

import styles from './BlogPage.module.scss'

const PressPage = (): JSX.Element => {

    return (
        <>

        <Helmet>
            <title>{BASE_PAGE_TILE}: Blog</title>
        </Helmet>
        <div className=''>
            <Hero
                imageM={heroImageM}
                imageD={heroImageD}
                imageDesc='cityside team'
                designElement='wild-circle'
            >
            <h1 className='headline__h1'>
                Blog
            </h1>
            </Hero>

            <div className={`grid-12 ${styles.container}`}>
                <div className={styles.inner}>
                    <div className='main-text'>
                        <center>
                            <h2 className='headline__h2 color--dark-blue'>
                                Changing to Cityside Fiber
                            </h2>
                            <br />
                            <br />

                            <img src={laptopInterconnect} width='50%' height='50%' loading='lazy' />
                        </center>

                        <br />
                        <p>
                            <u>Why Make the Switch?</u>
                        </p>
                        <p>
                            Before diving into the necessary steps to switch your ISP, let&apos;s talk about the why. Fiber internet is the internet of the future.
                            If you are still trying to meet your day-to-day work and entertainment needs by using cable internet, DSL, 5G, or satellite internet,
                            there is a much better and more efficient solution for you. Chances are, you are running into buffering, choppiness, and slow internet speeds.
                            It&apos;s getting old, isn&apos;t it?
                        </p>
                        <p>
                            Fiber internet (and #fiberwifi) will provide you with blazing-fast and symmetrical upload and download speeds,
                            reliable connectivity, a better value, and all-over better experience. Time to make the switch!
                        </p>
                        <p>
                            <u>Steps to Make the Switch -</u>
                        </p>
                        <p>
                            <ol>
                                <li>
                                    <b>Check out Cityside Fiber!</b> Other providers in the OC talk about Gig speeds but they only offer it on the download –
                                    they don&apos;t have fiber-optics so their upload speeds are about 1/10th of our 1 Gig package.
                                    Tha&apos;s why when you upload a file or have a live conference call you freeze up or your team can&apos;t hear or see you.
                                    Read customer reviews and see what current customers have to say about other providers
                                    (bad service, they don&apos;t answer the phone, too many outages, their service doesn&apos;t work in my area, and more...).
                                    Consider your current internet usage and what you don&apos;t like about your current plan.
                                </li>
                                <li>
                                    <b>Set up an exit strategy.</b> Most internet providers bill monthly, so you have a grace period of up to a month to set up
                                    your new connection without having to pay another bill from your old provider.  Also, check your contract if you have one.
                                </li>
                                <li>
                                    <b>Set up your new service.</b> Reach out to your new provider of choice to get the ball rolling.
                                    From there, the process is straightforward. It&apos;s okay for there to be an overlap between providers.
                                    Feel free to test out a new provider while still having your old one, as the grace period typically allows for enough time to cancel with a monthly billing cycle.
                                </li>
                                <li>
                                    <b>Cancel your old service.</b> Be sure to do this last after you confirm that your new #CitysideFiber service is up and running properly.
                                    Contact your old ISP via phone or go to a local store in person to officially part ways. There&apos;s a good chance they will try to win you back with promotions
                                    – but do your best to stay strong and resist all temptation. Remember why you&apos;re leaving in the first place... their service is no comparison to Cityside Fiber!
                                </li>
                                <li>
                                    <b>Return all equipment.</b> Promptly return all modems, routers, cable boxes, or other hardware to your provider&apos;s storefront.
                                    Getting rid of the equipment oftentimes provides you with all of the closure that you need (and helps you avoid late fees).
                                </li>
                            </ol>
                        </p>
                        <p>
                            <u>Ready to seal the deal and make the switch?</u>
                        </p>
                        <p>
                            Switching to fiber internet offers major benefits for a relatively minor inconvenience. After making the switch, you will save money,
                            have faster speeds, and more reliable connectivity to meet your work and entertainment needs. Ready to get started? Check our availability here to see if we&apos;re in your area yet!
                            We&apos;re proud to be your new #localinternetprovider.
                        </p>

                        <br />
                        <br />
                        <center>
                            <h2 className='headline__h2 color--dark-blue'>
                                Why Fiber Internet?
                            </h2>
                            <br />
                            <br />

                            <img src={fiberStock} width='50%' height='50%' loading='lazy' />
                        </center>

                        <br />
                        <p>
                            Fiber-optic internet and #fiberwifi blows cable out of the water. Since fiber technology is getting more popular,
                            customers have more #internetservice options to choose from between fiber and cable – and the better option is a no-brainer.
                            Fiber provides faster speeds, more reliable connectivity, and dedicated bandwidth, ensuring your service remains unaffected by your neighbors&apos; usage.
                            (No more waiting for pages to load and refreshing the screen during peak hours...)
                            Opting for fiber internet guarantees you the best online experience every time.
                        </p>
                        <p>
                            <u>The Speed You Need</u>
                        </p>
                        <p>
                            So why is fiber so much better and faster, you may ask? The method of data transmission between fiber and cable internet is the main difference between the two.
                            If we want to get real technical, fiber-optic technology utilizes thin, flexible glass strands to convey data through pulses of light.
                            These strands are grouped by layers of plastic, providing fiber optics faster, more precise, and capable of traveling greater distances than coaxial cables.
                            Why should you care? Fiber enables seamless streaming of high-definition videos, lighting-fast downloads of large files,
                            and smooth online gaming experiences sans buffering, slow connectivity, and lag. #Fiberspeed doesn&apos;t even compare to that of cable.
                        </p>
                        <p>
                            <u>Eliminating Bandwidth Sharing</u>
                        </p>
                        <p>
                            When it comes to “cable internet”, download speeds generally range from 10 Mbps to over 200 Mbps, while upload speeds fall short
                            which is why you get frozen face during digital conference calls. During peak hours, these speeds often tank due to bandwidth sharing.
                            This occurs because multiple households in your area are linked to the same cable line, so you have to “share” the connectivity among your neighbors.
                            Seems fair, right? In contrast, fiber-optic internet offers a dedicated connection to your home, ensuring that your neighbors hogging the internet won&apos;t impact your own connectivity...
                        </p>
                        <p>
                            <u>Internet of the Future</u>
                        </p>
                        <p>
                            Fiber is considered &quot;internet of the future&quot; because its bandwidth surpasses what we need as a society, even well into the future.
                            Technology is ever-evolving, and fiber ensures that we can keep up with whatever the future of technology holds for us.
                        </p>
                        <p>
                            <u>The Ultimate Customer Service Experience</u>
                        </p>
                        <p>
                            Our team (which includes real people!) is here for you with any questions or concerns.
                            We get it... it&apos;s beyond frustrating to be put on hold for hours on end or to be forced to work through automated menus trying to get the answer you need.
                            It is important to us that we help you out quickly and efficiently so you can move on with your day.
                        </p>
                        <p>
                            <u>Make the Switch to Cityside Fiber</u>
                        </p>
                        <p>
                            #Cityside Fiber is Orange County&apos;s new, local premiere #internetservice. Our fiber network offers lighting fast speeds, no lag or buffering,
                            and a concierge-level customer experience that sets us apart from monopoly internet service providers. With us, you&apos;ll never have to settle for internet quality that is less than what you deserve.
                            Curious to see if we&apos;re in our area? Check our availability here and email us at <a href='mailto: concierge@citysidefiber.com'><span className='bold'>concierge@citysidefiber.com</span></a> for updates as to when we will hit your neighborhood!
                        </p>

                        <br />
                        <br />
                        <center>
                            <h2 className='headline__h2 color--dark-blue'>
                                Why You Need Fiber in your Apartment!
                            </h2>
                            <br />
                            <br />

                            <img src={blogApartment} width='50%' height='50%' loading='lazy' />
                        </center>

                        <p>
                            Providing Cityside #fiberinternet is a way to stand out in the competitive rental market. Read how fiber internet can attract tenants and keep them happy!
                        </p>
                        <p>
                            <span className='bold'>Fiber internet is high-speed internet.</span>
                        </p>
                        <p>
                            In today&apos;s world, having fast and reliable connectivity is a necessity.
                            Lag and buffering can quickly become frustrating to tenants as they are trying to work from home, stream movies and TV, or engaging in online gaming.
                            Fiber offers incredibly fast speeds, much faster than traditional DSL or coaxial cable.
                            Your tech-savvy tenants will appreciate the ability to efficiently upload and download large files and stream their favorite programs seamlessly.
                        </p>
                        <p>
                            <span className='bold'>Fiber internet can enhance your property value.</span>
                        </p>
                        <p>
                            By providing access to our fast, fiber-optic internet, owners are given an advantage over properties that only offer mediocre, outdated internet services.
                            Prospective tenants, especially those who work remotely or rely heavily on the internet for entertainment, are likely to prioritize properties with fiber internet.
                        </p>
                        <p>
                            <span className='bold'>Fiber internet is reliable.</span>
                        </p>
                        <p>
                            Fiber internet is the most reliable internet available today.
                            To put it shortly so as not to bore you, copper-based connections including traditional coaxial cable and DSL are not shielded from inclement weather or other disturbances.
                            Fiber is protected against these instances, resulting in a more seamless online experience.
                            Property owners can sit back and relax knowing that their tenants are less likely to run into these common internet issues.
                        </p>
                        <p>
                            <span className='bold'>Fiber internet is scalable for changing needs.</span>
                        </p>
                        <p>
                            It is common for tenants&apos; internet needs to change overtime.
                            Whether it be adding more devices, starting to work from home more/less often,
                            or streaming more frequently, fiber allows you to easily upgrade your bandwidth depending on your requirements and lifestyle.
                        </p>
                        <p>
                            Fiber internet is a powerful tool for owners looking to increase their property value and tenant satisfaction.
                            Lightning speed internet value can make all the difference in attracting and retaining tenants.
                            Have we convinced you to get Cityside Fiber gig-speed internet for your property?
                            Check our availability <a href='https://www.citysidefiber.com/check-availability'><b><u>here</u></b></a> and email our VP of Sales, Mike Gourzis, at <a href='mailto: mike.gourzis@citysidefiber.com'><span className='bold'><u>mike.gourzis@citysidefiber.com</u></span></a> to learn more. We are proud to be your #localinternetprovider!
                        </p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};

export default PressPage;